<template>
  <div>
    <div class="group_1 flex-col">
      <div class="group_2 flex-col">
        <div class="block_3 flex-row">
          <div class="text-group_17 flex-col">
            <span class="text_1">渠道合作</span>
            <span class="text_2"
              >专注建筑能源领域，<br />携手扑沃共拓市场！</span
            >
          </div>
        </div>
        <div class="block_4 flex-row"><div class="block_1 flex-col"></div></div>
      </div>
    </div>

    <div class="icon_box">
      <div class="icon_box_item" v-for="(item, index) in desList" :key="index">
        <img :src="item.imgurl" alt="" />
        <div class="des_cls">{{ item.des }}</div>
        <div class="des_cls1">{{ item.des1 }}</div>
        <div class="des_cls2">{{ item.des2 }}</div>
      </div>
    </div>
    <div class="group_12 flex-row justify-between">
      <div class="group_5 flex-col"></div>
      <div class="text-wrapper_20 flex-col">
        <span class="text_8">渠道合作</span>
        <span class="text_9">Cooperation</span>
      </div>
    </div>
    <div class="cooperation">
      <div class="c_left_box">
        <div class="msg_line1">您可以在这里向我们了解关于扑沃以及合作事宜</div>
        <div class="msg_line2">
          <div class="line2_item">
            联系电话：<br />
            021-39882683
          </div>
          <div class="line2_item">
            全国热线：<br />
            177 2132 3870
          </div>
          <div class="line2_item">
            江浙沪热线：<br />
            13918812305
          </div>
        </div>
        <div class="msg_line3">
          公司邮箱：<br />
          puwo888@163.com
        </div>
        <div class="msg_line4">
          公司地址：<br />
          上海市奉贤区浦星公路9688弄6号6幢二层
        </div>
      </div>
      <div class="c_right_box">
        <div class="form_box">
          <el-form
            :model="formdata"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
          >
            <div class="right_line1">
              <el-form-item prop="name" class="line1_item">
                <el-input
                  v-model="formdata.name"
                  placeholder="请输入您的姓名"
                ></el-input>
              </el-form-item>
              <el-form-item prop="phone" class="line1_item">
                <el-input
                  v-model="formdata.phone"
                  placeholder="请输入您的电话"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item prop="email">
              <el-input
                v-model="formdata.email"
                placeholder="请输入您的邮箱"
              ></el-input>
            </el-form-item>

            <el-form-item prop="remark">
              <el-input
                v-model="formdata.remark"
                type="textarea"
                :rows="6"
                placeholder="请填写您的内容"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-button @click="sumbit" style="float: left">提 交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from "./assets/contact/c_icon1.png";
import img2 from "./assets/contact/c_icon2.png";
import img3 from "./assets/contact/c_icon3.png";
import img4 from "./assets/contact/c_icon4.png";
import img5 from "./assets/contact/c_icon5.png";
export default {
  data() {
    return {
      desList: [
        {
          imgurl: img1,
          des: "企业实力雄厚",
          des1: "500+客户",
          des2: "60+行业生态合作伙伴",
        },
        {
          imgurl: img2,
          des: "产品技术领先",
          des1: "150+技术团队实力见证",
          des2: "场景化SaaS",
        },
        {
          imgurl: img3,
          des: "丰富的市场资源",
          des1: "多渠道整体营销推广",
          des2: "标杆案例宣传、高端峰",
        },
        {
          imgurl: img4,
          des: "本地化服务",
          des1: "城市运营中心",
          des2: "随时提供本地化服务",
        },
        {
          imgurl: img5,
          des: "培训认证持续赋能",
          des1: "全线产品及服务培训",
          des2: "运营能力、市场销售集",
        },
      ],

      formdata: {
        name: "",
        phone: "",
        email: "",
        remark: "",
        companyname: "",
        profession: "",
        tel: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/,
            message: "请输入正确的手机号码",
          },
        ],
        email: [{ required: true, message: "请输入您的邮箱", trigger: "blur" }],
        remark: [{ required: true, message: "请输入留言", trigger: "blur" }],
      },
      submitURL: window.gurl.URL + "customerinfo/submit",
    };
  },
  computed: {
    showList() {
      return this.caseObj[this.caseType];
    },
  },
  methods: {
    async sumbit() {
      const res1 = await this.axios.post(this.submitURL, {
        ...this.formdata,
      });
      if (res1.rescode == 200) {
        this.$message.success({
          message: "提交成功，请等待客服联系",
          offset: 120,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.group_1 {
  background-image: url(./assets/contact/contactbg.png);
  background-size: cover;
  border: 1px solid rgba(151, 151, 151, 1);
}

.group_2 {
  background-color: rgba(24, 59, 124, 0.4);
  padding: 3.2vw 4vw 15.2vw 4vw;
}

.block_3 {
  margin: 16.8vw 5.6vw 0 0;
}

.text_1 {
  overflow-wrap: break-word;
  color: rgba(212, 222, 239, 1);
  font-size: 4.26vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.87vw;
  margin-right: 69.34vw;
}

.text_2 {
  width: 86.4vw;
  height: 17.6vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 6.4vw;
  font-family: AlibabaPuHuiTi-Bold;
  font-weight: 700;
  text-align: left;
  line-height: 8.8vw;
  margin-top: 1.34vw;
}

.block_4 {
  margin: 2.93vw 68vw 0 0;
}

.block_1 {
  background-color: rgba(255, 123, 52, 1);
  width: 24vw;
  height: 0.54vw;
}

.casetype_list_box {
  display: flex;
  width: 92vw;
  margin: 5vw auto;
  flex-wrap: wrap;
  .casetype_item_box {
    height: 10vw;
    min-width: 25vw;
    padding: 0 2vw;
    margin: 0 2vw 2vw 0;
    color: #fff;
    background-color: rgba(52, 57, 192, 1);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .no_selected {
    background-color: transparent;
    color: #222222;
  }
}

// 列表项
.list_3 {
  width: 92vw;
  margin: 0 auto;
  margin-top: 4vw;
}

.item_box {
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.45);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  width: 92vw;
  border: 1px solid rgba(244, 244, 244, 1);
  margin-bottom: 4vw;
}

.image_2 {
  width: 100%;
}

.text-group_18 {
  margin: 5.06vw 0vw 0 6.4vw;
}

.text_10 {
  overflow-wrap: break-word;
  color: rgba(52, 57, 192, 1);
  font-size: 3.2vw;
  font-family: AlibabaPuHuiTi-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 4.54vw;
  margin-right: 10.67vw;
}

.text_11 {
  width: 52.54vw;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 4.26vw;
  font-family: AlibabaPuHuiTi-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 5.87vw;
  margin-top: 0.8vw;
  margin-bottom: 6vw;
}
// 新版css
.icon_box {
  width: 92vw;
  margin: 3.9vw auto;
  display: flex;
  flex-wrap: wrap;
  .icon_box_item {
    width: 49%;
    text-align: center;
    margin-bottom: 3.9vw;
    img {
      width: 10.14vw;
      height: 10.14vw;
    }
    .des_cls {
      color: #222222;
      margin-bottom: 0.5vw;
      font-size: 4.26vw;
      letter-spacing: 0.3999999761581421px;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      white-space: nowrap;
      line-height: 5.87vw;
    }
    .des_cls1,
    .des_cls2 {
      color: rgba(102, 102, 102, 1);
      font-size: 3.2vw;
      letter-spacing: 0.29999998211860657px;
      font-weight: NaN;
      text-align: center;
      line-height: 4.54vw;
      margin-top: 1.6vw;
    }
  }
}

.group_12 {
  width: 44vw;
  margin: 5.4vw 48vw 0 4vw;
}

.group_5 {
  background-color: rgba(52, 57, 192, 1);
  width: 1.07vw;
  height: 9.87vw;
  margin: 0.8vw 0 1.33vw 0;
}

.text_8 {
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 3.73vw;
  letter-spacing: 0.3499999940395355px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 5.34vw;
  margin-right: 24.54vw;
}

.text_9 {
  overflow-wrap: break-word;
  color: rgba(45, 45, 45, 1);
  font-size: 5.33vw;
  text-transform: uppercase;
  font-family: Montserrat-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 6.4vw;
  margin-top: 0.27vw;
}

.cooperation {
  width: 92vw;
  margin: 2vw auto 0;
  padding-bottom: 20vw;
  .c_left_box {
    width: 100%;
    .msg_line1 {
      overflow-wrap: break-word;
      color: #000;
      font-size: 4.26vw;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      white-space: nowrap;
      line-height: 5.87vw;
      margin: 2.4vw 0 0 0;
    }
    .msg_line2 {
      display: flex;
      justify-content: space-between;
      margin-top: 1vw;
      flex-wrap: wrap;
      .line2_item {
        width: 49%;
        overflow-wrap: break-word;
        font-family: Montserrat-Regular;
        text-align: left;
        color: #2d2d2d;
        font-size: 4.8vw;
        text-align: left;
        line-height: 6.67vw;
        margin-top: 3vw;
      }
    }
    .msg_line3,
    .msg_line4 {
      text-align: left;
      color: #2d2d2d;
      font-size: 4.8vw;
      text-align: left;
      line-height: 6.67vw;
      margin-top: 3vw;
      font-family: Montserrat-Regular;
    }
  }
  .c_right_box {
    width: 100%;
    .form_box {
      margin-top: 5vw;
      .right_line1 {
        // display: flex;
        // justify-content: space-between;
        .line1_item {
          width: 100%;
        }
      }
    }
  }
}

.el-button {
  width: 100%;
  height: 12vw;
  border-radius: 0;
  color: #fff;
  background: #3439c0;
  border-radius: 23px;
}
::v-deep .el-input__inner {
  border-radius: 0;
  height: 50px;
  line-height: 50px;
  background-color: #f3f4f5;
}
::v-deep .el-textarea__inner {
  background-color: #f3f4f5;
}
</style>